import { Outlet, Link } from "react-router-dom";
import Config from "../Config";


const Layout = () => {
  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to={Config.url_api}>Home</Link>
          </li>
          <li>
            <Link to={Config.url_api + "exam-result"}>Result</Link>
          </li>
          
         
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;
