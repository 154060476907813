import React,{ useMemo } from 'react';
import { useParams } from 'react-router';
import '../css/pages/Assessment.css';
import Config from "../Config";
import axios from "axios";
import { Modal } from 'react-materialize';


class WritingPart extends React.Component{

	constructor(props) {

		super(props);
		
		
	}
	

	render(){


		return(<div>
			<h5>WRITING TASK {this.props.m_type == 1 ? "1":"2"}</h5>
			<div>{OptimizedTextComponent(this.props.intro_)}</div>
			<br/>
			<div className="main-intro">{OptimizedTextComponent(this.props.main_intro)}</div>
			<br/>
			<div>{OptimizedTextComponent(this.props.end_)}</div>
			{this.props.m_pic != null && <img style={{'width':"100%"}} src={Config.url_api + "documents/" + this.props.exam_id + "/writing/" + this.props.m_pic} />}

		</div>)
	}
}
function OptimizedTextComponent(text ) {
	
  


	return <div dangerouslySetInnerHTML={{ __html: text }} />;
  
}

class Assessment extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            'exam_id':null,
			'current':0,
            'part':[],
            'submit':false,
            'data_submit':null,
            'current_writing':["",""],
            "current_assessment":["","","",""],
            "point":0,
            'request_id':null,
            "session_id":null
        }
        this.selectPart = this.selectPart.bind(this);
        this.saveWriting = this.saveWriting.bind(this);
        this.selectPoint = this.selectPoint.bind(this);
        this.submitJob = this.submitJob.bind(this)
    }

    submitJob(){

        axios.post(Config.url_api + "api/exam-writing-confirm",{
            session_id:this.state.session_id,
            request_id:this.state.request_id,
            point:this.state.point,
            assessment:[this.state.current_assessment[2],this.state.current_assessment[3]]
        })
		.then(function(response){

           // exam-writing-confirm
        });
        
    }
    selectPoint(e){
        this.setState({point:e.target.value});
       
    }

    selectPart(e){
		const part = e.target.dataset.value;
		this.setState({current:part});
	}


    saveWriting(e){
		const part = e.target.dataset.part;
		let current_assessment = this.state.current_assessment;
		current_assessment[part] = e.target.value;
		this.setState({current_assessment:current_assessment});
		
	}
    componentDidMount(){

        var that = this
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const request_id = urlParams.get("request_id");
		
		const url_api = Config.url_api;
        axios.get(url_api + "api/exam-assessment/" + request_id)
		.then(function(response){
             console.log(response.data);
            
            const data_submit = response.data.submit_check;
            const writing_set = [data_submit.part_1,data_submit.part_2];
            const session_id = data_submit.session_id;
            const submit = data_submit.status == "done" ? true:false;

            let current_assessment = that.state.current_assessment;
            if(submit){

                current_assessment[2] = data_submit.check_part1;
                current_assessment[3] = data_submit.check_part2;
            }
            that.setState({data_submit:data_submit,
                current_writing:writing_set,
                session_id:session_id,
                request_id:request_id,
                submit:submit,
                current_assessment:current_assessment,
                part:response.data.data});

        });
    }
    render(){
        const current = this.state.current;
        const points = [];
        for(let i = 9; i >= 0; i -= 0.5){
            
            points.push(<option key={i} value={i}>{i}</option>)
        };
        return(<div>
            <div className="row">
				<div className="col s12"><h4>Writing</h4></div>
                <div className="col s12">
                    {typeof(this.state.part[0]) != "undefined" && <button onClick={this.selectPart} data-value="0" className={"btn select-part-button " + (this.state.current == 0 ? "active":"") }>Part 1</button>}
				    {typeof(this.state.part[1]) != "undefined" && <button onClick={this.selectPart} data-value="1" className={"btn select-part-button " + (this.state.current == 1 ? "active":"")}>Part 2</button>}
                    {typeof(this.state.part[0]) != "undefined" && <button onClick={this.selectPart} data-value="2" className={"btn select-part-button " + (this.state.current == 0 ? "active":"") }>Assessment part 1</button>}
                    {typeof(this.state.part[0]) != "undefined" && <button onClick={this.selectPart} data-value="3" className={"btn select-part-button " + (this.state.current == 0 ? "active":"") }>Assessment part 2</button>}
                    {!this.state.submit && <button href="#Modal-15" className="btn select-part-button modal-trigger ">Submit</button>}
                </div>
            </div>
            <Modal id="Modal-15">
                <div className="row">
                    <div className="col s3">
                        <div>Writing point</div><br/>
                        <select onChange={this.selectPoint} className="browser-default">{points}</select><br/>
                        <div><button onClick={this.submitJob} className="btn select-part-button">Submit</button></div>
                    </div>

                </div>
                
                
            </Modal>
            <div className="row">
                <div  className="col l6">
                    <div style={{'height':"800px",'overflowY':"scroll"}}>
                        {(typeof(this.state.part[current]) != "undefined" && (current == 0 || current == 1) )  && <WritingPart  {...this.state.part[current]} />}
                        {current == 2 && <textarea style={{'height':"95%"}} disabled={true} value={this.state.data_submit.part_1}></textarea>}
						{current == 3 && <textarea style={{'height':"95%"}} disabled={true} value={this.state.data_submit.part_2}></textarea>}
                    </div>
                </div>
                <div className="col l6">
                    <div style={{'height':"800px",'overflowY':"scroll"}}>
                    {(typeof(this.state.part[current]) != "undefined" && (current == 0 || current == 1) ) && <textarea disabled={true} style={{'height':"95%"}} data-part={this.state.current} onChange={this.saveWriting}  value={this.state.current_writing[current]} ></textarea>}
                    {(current == 2 || current == 3)  && <textarea disabled={this.state.submit} onChange={this.saveWriting} style={{'height':"95%"}} data-part={current} value={this.state.current_assessment[current]}></textarea>}
                    </div>
                </div>
            </div>    
        </div>);
    }
}


export default Assessment;