import React,{ useMemo } from 'react';
import { useParams } from 'react-router';
import '../css/pages/Listening.css';
import Config from "../Config";
import axios from "axios";
import 'materialize-css';
import 'materialize-css/dist/css/materialize.css';
import { Button, Card, Row, Col,Modal,Select } from 'react-materialize';
import {TestMe,QuesionShow} from "../components/Exam";


class Listening extends React.Component{

    constructor(props){
        super(props);
        this.state = {    
            doc:[],
            myAnswer:this.initMyAnswers(),
  		    currentPart:null,
            part:[],
            submit:false,
            lastPart:1,
        }
        this.selectPart = this.selectPart.bind(this);
        this.nextPart = this.nextPart.bind(this);
        this.getSelectAnswer = this.getSelectAnswer.bind(this);
        this.getFillAnswer = this.getFillAnswer.bind(this);
        this.processSubmit = this.processSubmit.bind(this);

    }
    initMyAnswers(){
		const myAnswer = {};
		const total = 40;
		 for(var i = 1 ; i <= total;i++){

			myAnswer[i] = null;
		 }

		 return myAnswer;
	}
    componentDidMount(){
        
        var that = this;
        const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
        const session_id = urlParams.get("session_id");
        const url_api = Config.url_api;
        axios.get(url_api + "api/exam-content/" + session_id)
        .then(function(response){
            var part = [] 
           
            for(var i = 0; i < response.data[0].length;i++){
			
				if(typeof part[response.data[0][i].part] === "undefined" ){

					part[response.data[0][i].part] = [];
				}
				part[response.data[0][i].part].push(response.data[0][i]);
			}
            const exam_id = response.data[1].exam_id;
            that.setState({"exam_id":exam_id,part:part,"session_id":session_id});
        });

        axios.get(url_api + "api/exam-doc/" + session_id)
		.then(function(response){
           
			var doc = [];
			
			for(var i = 0; i < response.data.length;i++){
				
				if(typeof doc[response.data[i].part] === "undefined" ){

						doc[response.data[i].part] = [];
				}
				
				doc[response.data[i].part] = response.data[i];
			}
            const lastPart = doc[doc.length -1].part;
			that.setState({doc:doc,lastPart:lastPart},function(){
             
				if(typeof(that.state.doc[1]) != "undefined"){
					that.setState({currentPart:1});
                    
				}
				
			});
		});


    }
    selectPart(e){
		const part = e.target.dataset.value;
		
        this.setState({currentPart:part},function(){

				console.log(this.state);
        });

		

	}
    nextPart(e){
        const next = e.target.dataset.next;
        this.setState({currentPart:next});
    }
    getSelectAnswer(e){
        const id = e.target.getAttribute('data-id');
	
		let answers = this.state.myAnswer;
		var that = this;
		if(typeof answers[id] != "undefined"){

			answers[id] = e.target.value;
			this.setState({myAnswer:answers},function(){

				
			});
		}

    }
    getFillAnswer(e){
		const id = e.target.getAttribute("data-id");
		let answers = this.state.myAnswer;
		var that = this;
		if(typeof answers[id] != "undefined"){

			answers[id] = (e.target.value == "" ? null:e.target.value.trim());
			this.setState({myAnswer:answers},function(){

				
			});
		}
		

	}
    processSubmit(e){
        const confirmMe = window.confirm("Do you want to submit?");
        if(!confirmMe){

            return false;
        }
		var that = this;
		that.setState({"loading":true});
		const url_api = Config.url_api;
		
		axios.post(url_api + "api/exam-submit/",{
			'type':"listening",
			'answer':this.state.myAnswer,
			'exam_id':this.state.exam_id,
			'session_id':this.state.session_id
		})
		.then(function(response){
            
            window.location.replace("/your-result?session_id=" + that.state.session_id);
		})

	}
    render(){

        var that = this;
        const currentPart = this.state.currentPart;
        const soundType = ".m4a";
        const audioPath = Config.url_api + "documents/" + this.state.exam_id + "/listening/part_" + currentPart + soundType;
        
        const pdfPath = Config.url_api + "documents/" + this.state.exam_id + "/main_doc/";
        const partButton = this.state.doc.map( (item,pos) => {

                const active = currentPart == item.part ? "active":"";
		    	return(<button data-value={item.part} onClick={that.selectPart} className={"btn select-part " + active} key={pos}>Part {item.part}</button>)

        }
		);
        const myAnswer = Object.keys(this.state.myAnswer).map(function(a,b){
		
			return <button style={{'marginRight':'2px'}} className={"waves-effect  btn-small " + (that.state.myAnswer[a] != null ? "orange darken-1":"red lighten-2")} key={a}>{a}</button>
		});
        return(<div id="m-root">
                <div className="row">
                    <div className="col s12">
                        <h1>Listening</h1>
					
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col s12">{partButton}</div>
                </div>
                <div className="row">
                    <div className="col s12">
                        {currentPart != null && <audio controls id={"audio_" + currentPart} src={audioPath}></audio>}
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        {myAnswer}
                    </div>
                </div>
                <div className="row">
                    <div className="col l6" id="left-panel">
						{(currentPart != null && typeof(this.state.doc[currentPart]) !=  "undefined") && <div style={{'width':"100%"}}>
							<iframe style={{'width':"100%","height":"850px"}} src={pdfPath + this.state.doc[currentPart].path} ></iframe>
						</div>}
					</div>
                    <div className="col l6" id="right-panel">
                        {typeof(this.state.part[currentPart]) != "undefined" && this.state.part[currentPart].map((o)=><QuesionShow
                                                                                                                        exam_type="listening"
																														submit = {this.state.submit || this.state.timeOut} 
																														getFillAnswer = {this.getFillAnswer}		
																														getSelectAnswer={this.getSelectAnswer}
																														key={o.id} {...o} 
																														questions={JSON.parse(o.questions)} />)

						}
                        {currentPart != this.state.lastPart && <button className="btn select-part" data-next={parseInt(currentPart)+1} onClick={this.nextPart}>Next Part</button>}
                        {currentPart == this.state.lastPart && <button disabled={this.state.loading} onClick={this.processSubmit} className="btn select-part">Submit</button>}

                    </div>
                </div>

        </div>)
       
    }


}


export default Listening;
