import React,{ useMemo } from 'react';
import { useParams } from 'react-router';

import Config from "../Config";
import axios from "axios";
import '../css/pages/ReadingResult.css';



class ReadingResult extends React.Component{


    constructor(props) {


        super(props);
  		this.state = {
            result:{},
            type:"",
            name:"",
            mode:"total",
            category:{},
            exam_type:"reading",
            bandScore:0 
        }
        this.changeMode = this.changeMode.bind(this);
    }

    componentDidMount(){

        var that = this;
        const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const session_id = urlParams.get("session_id");
        const url_api = Config.url_api;
		axios.get(url_api + "api/exam-show?student_session_id=" + session_id)
        .then(function(response){

            that.setState({
                exam_type:response.data.type,
                result:response.data.result,
                type:response.data.type,
                name:response.data.name,
                category:response.data.category_score,
                bandScore:response.data.score
            
            });
           

        })
    }
    changeMode(e){
        const type = e.target.dataset.type;
        this.setState({mode:type});
        

    }
    getBandScore(type,inputScore){

        let score = 0;
        const bandScoreAcademic = [
            [40,39,9],
            [38,37,8.5],
            [36,35,8],
            [34,33,7.5],
            [32-30,7],
            [29,27,6,5],
            [26,23,6],
            [22,19,5.5],
            [18,15,5],
            [14,13,4.5],
            [12,10,4],
            [9,8,3.5],
            [7,6,3],
            [5,4,2.5]
        ];
        
        const bandScoreGeneral = [

            [40,40,9],
            [39,39,8.5],
            [38,37,8],
            [36,36,7.5],
            [35,34,7],
            [33,32,6.5],
            [31,30,6],
            [29,27,5.5],
            [26,23,5],
            [22,19,4.5],
            [18,15,4],
            [14,12,3.5],
            [11,9,3]


        ];

        const bandScore = type == "academic" ? bandScoreAcademic:bandScoreGeneral;
        for(var i = 0; i < bandScore.length;i++){

            if(inputScore <= bandScore[i][0] && inputScore >= bandScore[i][1]){
                score = bandScore[i][2];
                break;
            }
        }

        return score;
    }
    render(){
        var that = this;
        
        let yourPoint = 0;
        const myResult = Object.keys(this.state.result).map(function(key){
            
            let allAns = "";
            const mAnswer = that.state.result[key];
            if(Array.isArray(mAnswer.answer)){
             
                allAns = mAnswer.answer.join(' / ');

            }else if( typeof mAnswer.answer === 'string' || mAnswer.answer instanceof String){
                allAns = mAnswer.answer;
               
            }
          
            yourPoint = yourPoint + (mAnswer.correct ? 1:0);
         
            return (<tr key={key}>
                <td>{key}</td>
                <td>{allAns}</td>
                <td>{mAnswer.your_answer}</td>
                <td><i style={{'color':(mAnswer.correct ? "#2e8e06":"#cc0000")}} className="material-icons dp48">{mAnswer.correct ? "done":"close"}</i></td>
            </tr>)
        });

        const mCategory = Object.keys(this.state.category).map(function(key){
            console.log(key);
            const _row = that.state.category[key];
            console.log(key,_row);
            return (<tr key={key}>
                <td>{_row.desc}</td>
                <td>{_row.total}</td>
                <td className="row_score">{_row.student_score}</td>

            </tr>);

        });
        
        const bandScore = this.state.bandScore;
        return(<div>
            <div className="row">

                <div className="col s12">
                    
                </div>
            </div>
            <div className="row">
                
                <div className="offset-l4 col l4">
                <h5>{this.state.name}</h5>
                <h5>Your point: {yourPoint}/40</h5>
                <h5>{this.state.exam_type =="reading" ? "Reading":"Listening"} band scores : <span style={{'color':"#e31837"}}>{bandScore}</span></h5>
                <button data-type="total" onClick={this.changeMode} disabled={this.state.mode == "total"} className="waves-effect waves-light btn-small">Summary</button> <button onClick={this.changeMode} data-type="category" disabled={this.state.mode == "category"} className="waves-effect light-blue darken-2 btn-small">Score by Category</button>
                <br/>
                <br/>
                {this.state.mode == "total" && <table id="main-table" className="highlight">
                    <thead>

                        <tr>
                            <td style={{'whiteSpace':"nowrap"}}>Question #</td>
                            <td>Correct answer</td>
                            <td>Your answer</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {myResult}
                    </tbody>
                </table>}
                {this.state.mode == "category" && <a target="_blank" href="https://ieltsliz.com/ielts-reading-question-types/">Question types </a>}
                {this.state.mode == "category" && <table id="main-table" className="highlight">
                    <thead>

                        <tr>
                            <td style={{'whiteSpace':"nowrap"}}><b>Question type</b></td>
                            <td><b>Total</b></td>
                            <td><b>Your score</b></td>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {mCategory}
                    </tbody>
                    
                </table>}
                </div>
            </div>
        </div>);
    }
}

export default ReadingResult;