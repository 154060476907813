import React,{ useMemo } from 'react';
import { useParams } from 'react-router';

import Config from "../Config";
import axios from "axios";
import '../css/pages/Reading.css';
import {QuesionShow,OptimizedTextComponent} from "../components/Exam";
import 'materialize-css';
import 'materialize-css/dist/css/materialize.css';
import { Button, Card, Row, Col,Modal,Select } from 'react-materialize';







class SelectQuestion extends React.Component{



}

function RenderSelectBox(props){

	
	
	return(<select disabled={props.submit} data-id={props.id} onChange={props.getSelectAnswer} style={{'width':"auto","backgroundColor":"beige"}} className=" browser-default"><option>Select your answer</option>{props.answer.map((o,i) =>(

					<option  key={i} value={i}>{o}</option>
					)
	 )}</select>)




}

class Reading extends React.Component{

	constructor(props) {

  		super(props);
  		this.state = {

  			part:[],
  			doc:[],
  			currentPart:null,
  			exam_id:null,
  			myAnswer:this.initMyAnswers(),
			submit:false,
			timeOut:false,
			session_id:null,
			loading:false,
			set_time:false,
			time_max:-3600,
			minutesRemain:60,
			timeRemainCheck:true

  		}
		
  		this.selectPart = this.selectPart.bind(this)
		this.getSelectAnswer = this.getSelectAnswer.bind(this)
		this.getFillAnswer = this.getFillAnswer.bind(this)
		this.processSubmit = this.processSubmit.bind(this)
	}
	initMyAnswers(){
		const myAnswer = {};
		const total = 40;
		 for(var i = 1 ; i <= total;i++){

			myAnswer[i] = null;
		 }

		 return myAnswer;
	}

	getSelectAnswer(e){
		const id = e.target.getAttribute('data-id');
	
		let answers = this.state.myAnswer;
		var that = this;
		if(typeof answers[id] != "undefined"){

			answers[id] = e.target.value;
			this.setState({myAnswer:answers},function(){

				
			});
		}
		
	}
	getFillAnswer(e){
		const id = e.target.getAttribute("data-id");
		let answers = this.state.myAnswer;
		var that = this;
		if(typeof answers[id] != "undefined"){

			answers[id] = (e.target.value == "" ? null:e.target.value.trim());
			this.setState({myAnswer:answers},function(){

				
			});
		}
		

	}
	componentDidMount(){

		var that = this
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const session_id = urlParams.get("session_id");
		
		const url_api = Config.url_api;
		if(session_id == null){

			return false;
		}
		this.setState({session_id:session_id});
		axios.get(url_api + "api/exam-content/" + session_id)
		.then(function(response){
			let submit = false;
			let timeRemainCheck = true;
			let time_show = null;
			const set_time = response.data[1].set_time == 0 ? false:true;
			let minutesRemain = 60;
			if(set_time){

				const timeRemain = response.data[1].secondRemain;
				if(that.state.time_max <= timeRemain && timeRemain <= 0 ){
					
					timeRemainCheck = true;
					
					minutesRemain = Math.abs((that.state.time_max - timeRemain)/60);
					
				}else{
					timeRemainCheck = false;
					submit = true;
				}


			
			}
			var part = [] 
			var exam_id = "";
			for(var i = 0; i < response.data[0].length;i++){
				exam_id = response.data[0][i].exam_id;
				if(typeof part[response.data[0][i].part] === "undefined" ){

					part[response.data[0][i].part] = [];
				}
				part[response.data[0][i].part].push(response.data[0][i]);
			}
			that.setState({part:part,
				exam_id:exam_id,
				timeRemainCheck:timeRemainCheck,
				set_time:set_time,
				submit:submit,
				minutesRemain:minutesRemain},function(){

					if(that.state.set_time){
						const timeCount = setInterval(()=>{

						
							const currentDate = new Date();
							let minutesRemain = that.state.minutesRemain - 1;
						
							that.setState({minutesRemain:minutesRemain});
							if(minutesRemain <= 0){
								
								document.getElementById("submit-button").click(); 
								that.setState({'timeOut':true,timeRemainCheck:false});
								clearInterval(timeCount);
								
							}
						
						},60000);

					}
					

				});
			

		});
		
		

		axios.get(url_api + "api/exam-doc/" + session_id)
		.then(function(response){
			var doc = [];
			
			for(var i = 0; i < response.data.length;i++){
				
				if(typeof doc[response.data[i].part] === "undefined" ){

						doc[response.data[i].part] = [];
				}
				
				doc[response.data[i].part] = response.data[i];
			}
		
			that.setState({doc:doc},function(){
			
				if(typeof(that.state.doc[1]) != "undefined"){
					that.setState({currentPart:1});
				}
				
			});
		});
		
		
	}

	selectPart(e){
		const part = e.target.dataset.value;
		var that = this;
		if(typeof(this.state.part[part]) != "undefined"){

			this.setState({currentPart:part},function(){

				
			});
		}

	}
	answersDone(answers){

		let result = true;

		for(const answer in answers){

			if(answers[answer] == null){

				result = false;
				break;

			}
		}
		return result;
	}
	processSubmit(e){

		var that = this;
		that.setState({"loading":true});
		const url_api = Config.url_api;
		
		axios.post(url_api + "api/exam-submit/",{
			'type':"reading",
			'answer':this.state.myAnswer,
			'exam_id':this.state.exam_id,
			'session_id':this.state.session_id
		})
		.then(function(response){
			window.location.replace("/your-result?session_id=" + that.state.session_id);

		})

	}

	
	render(){
		const currentPart = this.state.currentPart;
		const pdfPath = Config.url_api + "documents/" + this.state.exam_id + "/main_doc/";
		
		var that = this;

		const answerDone = this.answersDone(this.state.myAnswer);
		const myAnswer = Object.keys(this.state.myAnswer).map(function(a,b){
		
			return <button style={{'marginRight':'2px'}} className={"waves-effect  btn-small " + (that.state.myAnswer[a] != null ? "orange darken-1":"red lighten-2")} key={a}>{a}</button>
		});
		
		
		return(<div id="m-root">

			<div className="row">
				
				<div className="col s12">
					<h1>Reading</h1>
					<div>

					
						{typeof(this.state.part[1]) != "undefined" && <button className={"btn select-part " + (currentPart == 1 ? "active":"")} data-value="1" onClick={this.selectPart}>Part 1</button>}
						{typeof(this.state.part[2]) != "undefined" && <button className={"btn select-part " + (currentPart == 2 ? "active":"")} data-value="2" onClick={this.selectPart}>Part 2</button>}
						{typeof(this.state.part[3]) != "undefined" && <button className={"btn select-part " + (currentPart == 3 ? "active":"")} data-value="3" onClick={this.selectPart}>Part 3</button>}
						{typeof(this.state.part[4]) != "undefined" && <button className={"btn select-part " + (currentPart == 4 ? "active":"")} data-value="4" onClick={this.selectPart}>Part 4</button>}
						{!this.state.timeOut && <button disabled={this.state.submit} id="submit-button" href="#Modal-12" className="select-part modal-trigger btn green darken-1">Submit your answers</button>}
						{(this.state.set_time && this.state.timeRemainCheck) && <button className="btn"><i style={{'verticalAlign':"bottom"}}className="material-icons dp48">alarm</i> <span>{Math.round(this.state.minutesRemain)}</span></button>}
						{(this.state.set_time && this.state.timeOut && !this.state.timeRemainCheck) && <button  href="#Modal-12" className="select-part modal-trigger btn green darken-1">Submit your answers</button>}
						<Modal id="Modal-12">
							{ (this.state.timeRemainCheck &&!answerDone) &&<div>There are many questions unanswer. Are you sure to submit?</div>}
							{(this.state.timeRemainCheck && answerDone) &&<div>Are you sure to submit?</div>}
							{!this.state.timeRemainCheck && <div>The time is over. Please submit to see the result.</div>}
							<br/>
							<div><button disabled={this.state.loading} onClick={this.processSubmit} className="btn orange darken-1">Submit</button></div>
						</Modal>
						

					</div>
				</div>	
			</div>
			<div className="row">

				<div className="col s12">
					{myAnswer}
				</div>
			</div>
			
			<div>
				<div className="row">
					<div className="col l6" id="left-panel">
						{(currentPart != null && typeof(this.state.doc[currentPart]) !=  "undefined") && <div style={{'width':"100%"}}>
							<iframe style={{'width':"100%","height":"850px"}} src={pdfPath + this.state.doc[currentPart].path} ></iframe>
						</div>}
					</div>
					<div className="col l5" id="right-panel">

						{typeof(this.state.part[currentPart]) != "undefined" && this.state.part[currentPart].map((o)=><QuesionShow
																														exam_type="reading"
																														submit = {this.state.submit || this.state.timeOut} 
																														getFillAnswer = {this.getFillAnswer}		
																														getSelectAnswer={this.getSelectAnswer}
																														key={o.id} {...o} 
																														questions={JSON.parse(o.questions)} />)

						}
					</div>
				</div>
			</div>
			
			</div>)
	}
}

export default Reading;
