import React,{ useMemo,useState,useEffect } from 'react';
import { useParams } from 'react-router';
import Blob1 from 'react';
import Config from "../Config";
import axios from "axios";
import '../css/pages/Speaking.css';
import 'materialize-css';
import 'materialize-css/dist/css/materialize.css';
import { Button, Card, Row, Col,Modal,Select } from 'react-materialize';
import { AudioRecorder,useAudioRecorder } from 'react-audio-voice-recorder';







function MAudioPlay(props){
    const mPath =  Config.url_api + "speaking_submit/" +  props.session + "/" + props.part + ".ogg";
    return(<div>
         <h4>{props.name}</h4>  
        <audio controls>
            <source src={mPath} type="audio/ogg"/>
        </audio>
    </div>)
}
function SoundRecord(props){

   
    

    const [dataRecord,changeState] = useState(false);
    const [uploadState,setUpload] = useState(false);
    const recorderControls = useAudioRecorder(
        {
          noiseSuppression: true,
          echoCancellation: true,
        },
        (err) => console.table(err) // onNotAllowedOrFound
    );

    /*useEffect(() => {    


       if(uploadState){
            console.log("complete upload");
       } 


    },[uploadState]);*/
    function addAudioElement(blob){
        
        console.log("done me" + Date.now());
        changeState(true);
        const url = URL.createObjectURL(blob);
        const audio = document.createElement("audio");
        audio.src = url;
        audio.controls = true;
        //document.body.appendChild(audio);
        //props.changeStateRecord(props.pos,true);
      
    };
    function resetMe(){
        if (window.confirm("Your previous record will be lost") == true) {
            changeState(false);
            
        }

    };
    function uploadMe(){
        setUpload(true);
        var formData = new FormData();
        formData.append("sound_file", recorderControls.recordingBlob);
        formData.append("type","speaking-part");
        formData.append("part",props.id);
        formData.append("session_id",props.session_id);
        axios.post(Config.url_api + "api/exam-submit/",formData,{

            headers:{
                'Content-Type': 'multipart/form-data'
            }
        })
		.then(function(response){
           
            props.changeStateRecord(props.pos,true,props.id,response.data);
            //setUpload(false);
           /* document.getElementById("upload-" + props.id).style.display = "none"; 
            document.getElementById("reset-" + props.id).style.display = "none"; 
            const url = URL.createObjectURL(recorderControls.recordingBlob);
            const url = Config.url_api + "speaking_submit/" + props.session_id + "/" + props.id + ".ogg";
           
            const audio = document.createElement("audio");
            audio.src = url;
            audio.controls = true;
            document.getElementById("record-" + props.id).appendChild(audio);*/
			

		})
        console.log(recorderControls.recordingBlob);

    };
    if(props.state == true){

        return (<div>Done</div>);
    }
    return (
        <div id={"record-" + props.id}>
          <h4>{props.name}</h4>  
          <div className={"record-icon-" + (dataRecord ? "off":"on")}>
          <AudioRecorder
            onRecordingComplete={addAudioElement}
            recorderControls={recorderControls}
            // downloadOnSavePress={true}
            // downloadFileExtension="mp3"
            showVisualizer={true}
            class={"record_me " + props.id}
          />
          <br />
          </div>
          
        
          {dataRecord  && <button disabled={uploadState} id={"upload-" + props.id} onClick={uploadMe} className="waves-effect waves-light btn-small select-part">{!uploadState ? "Upload":"Uploading..."}</button>}
          {dataRecord &&  <button disabled={uploadState} id={"reset-" + props.id} className="btn-small" onClick={resetMe}>Reset</button>}
          <br />
        </div>
      );
}

class Speaking extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            "exam_id":null,
            "doc":null,
            'recordControl':null,
            'session_id':null,
            "request_wait":[],
            'status':null,
            'partState':[false,false,false],
            'who_check':"",
            "mode":"test",
            "request_id":"",
            "notes":"",
            "score":0
        }

        this.completeRecording = this.completeRecording.bind(this);
        this.changeStateRecord = this.changeStateRecord.bind(this);
        this.acceptRequest = this.acceptRequest.bind(this);
        this.submitAssessment = this.submitAssessment.bind(this);
        this.saveWorking = this.saveWorking.bind(this);

    }
    
    changeStateRecord(pos,state,id,response){

        var that = this;
        let partState = this.state.partState;
        partState[pos] = state;
        for(var i = 0; i <=2;i++){
            partState[i] = response['part_' + (i+1)] == 1 ? true:false;   
        }
        this.setState({partState:partState},function(){

            console.log(that.state);
        });

    }
    acceptRequest(e){
        const id = e.target.dataset.id;
		const name = e.target.dataset.name;
		
		var that = this;
		axios.post(Config.url_api + "api/request-confirm",{id:id,session_id:this.state.session_id,type:"speaking"})
		.then(function(response){
			
            that.setState({
                'status':"accept",
                "who_check":name
            });
            
			//let data_submit = that.state.data_submit;
			//data_submit.status = "accept";
			//that.setState({data_submit:data_submit,who_check:name});

		});

    }
    componentDidMount(){
        var that = this
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
        let session_id = urlParams.get("session_id");
		const mode = urlParams.get("mode") == null ? "test":urlParams.get("mode");
        const request_id = urlParams.get("request_id");
		const url_api = Config.url_api;
		if(session_id == null && request_id == null){

			return false;
		}
       
        axios.get(url_api + "api/exam-speaking",{
            params:{mode:mode,session_id:session_id,request_id:request_id}
        })
		.then(function(response){
            
            if(mode == "check"){

                session_id = response.data.session_id;
            }
            const mState = response.data.state;
            that.setState({exam_id:response.data.exam_id,
                "session_id":session_id,
                'partState':mState,
                'status':response.data.status,
              
                "mode":mode,
                "score":response.data.score,
                "notes":response.data.notes,
                'request_id':request_id
            });

        })
        .catch(function(error){
            console.log(error);
           // alert("Server error");
        });
       

    }
    completeRecording(blob){

        console.log(blob);

    }
    submitAssessment(e){

        console.log(this.state);
        const url_api = Config.url_api;
        axios.post(url_api + "api/speaking-assessment-submit",{
           request_id:this.state.request_id,notes:this.state.notes,score:this.state.score
        }).then(function(data){

        });

    }
    saveWorking(e){

        const type = e.target.getAttribute('data-type');
        let saveMe = {};
        if(type == "notes"){
            saveMe['notes'] = e.target.value;
            const text = e.target.value;
        }
        if(type == "score"){

            saveMe['score'] = e.target.value;
        }
        this.setState(saveMe);
        

    }
    render(){
        const pdfPath = Config.url_api + "documents/" + this.state.exam_id + "/speaking/speaking.pdf";
        var that = this;
        

        let points = [];
        for (let i = 1; i <= 9;i += 0.5){

            points.push(<option key={i} value={i}>{i}</option>);
        }
        return(<div id="m-root">
            <div className="row">
				
				<div className="col s12"><h1>Speaking</h1></div>
                <div className="col 12">
                    {(this.state.status == "waiting" && this.state.request_wait.length > 0) &&  <button href="#Modal-14" className="select-part-button btn modal-trigger">Examiner requests</button>}
                    {(this.state.status == "accept" && this.state.mode == "test") && <button href="#Modal-14" className="select-part-button btn modal-trigger">Examiner info</button>}
                </div>
            </div>
           
            <Modal id="Modal-14"
                
            >
						{this.state.status == "accept" && <div>You has set <b>{this.state.who_check}</b> to give assesment for your writing.</div> }
						{this.state.status == "waiting" && this.state.request_wait.map( (a) => <div 
						key={a.id}><b>{a.name}</b> want to give assessment your speaking <button 
						onClick={that.acceptRequest} data-name={a.name} data-id={a.id} className="btn-small light-green darken-1">Accept</button></div>)}
			</Modal>
            <div className="row">

                <div className="col l6" id="left-panel">
						{this.state.exam_id !=  null && <div style={{'width':"100%"}}>
							<iframe style={{'width':"100%","height":"850px"}} src={pdfPath} ></iframe>
						</div>}
					</div>
                    <div className="col l6" id="right-panel">
                        {!this.state.partState[0] && <div>
                            <SoundRecord pos={0}  
                            changeStateRecord={this.changeStateRecord}
                        
                            id="part_1" name="Part 1" 
                            session_id={this.state.session_id}
                            />
                        </div>}
                        {this.state.partState[0] && <MAudioPlay
                                                        session={this.state.session_id}
                                                        name="Part 1"
                                                        part="part_1"       
                        />}
                        {!this.state.partState[1] && <div>
                            <SoundRecord pos={1}  
                            changeStateRecord={this.changeStateRecord}
                        
                            id="part_2" name="Part 2" 
                            session_id={this.state.session_id}
                            />
                        </div>}
                        {this.state.partState[1] && <MAudioPlay
                                                        session={this.state.session_id}
                                                        name="Part 2"
                                                        part="part_2" 
                                                        />}
                        {!this.state.partState[2] && <div>
                            <SoundRecord pos={2}  
                            changeStateRecord={this.changeStateRecord}
                        
                            id="part_3" name="Part 3" 
                            session_id={this.state.session_id}
                            />
                        </div>}
                        {this.state.partState[2] && <MAudioPlay
                                                        session={this.state.session_id}
                                                        name="Part 3"
                                                        part="part_3" 
                                                        />}

                        {(this.state.mode == "test" && this.state.partState[0] ==  true && this.state.partState[1] == true && this.state.partState[2] == true) && <button className="btn waves-effect waves-light" disabled={true}>Submitted</button>}                                
                        {((this.state.status == "accept" || this.state.status == "complete") && this.state.mode == "check") && <div>
                            <h5>Your assessment</h5>
                            <div className="row">
                                <div className="col s3">
                                    <label>Score</label>
                                    <select value={that.state.score} disabled={this.state.status == "complete"} onChange={this.saveWorking} data-type="score" className="browser-default">
                                        {points}
                                    </select> 
                                    
                                </div>
                                <div className="col s12">
                                    <br/>
                                    <div><label>Notes</label></div>
                                    <textarea disabled={this.state.status == "complete"} data-type="notes" onChange={this.saveWorking} value={this.state.notes}></textarea>

                                </div>
                                <div className="col s12">

                                    <button disabled={this.state.status == "complete"} onClick={this.submitAssessment}>Submit</button>
                                </div>
                            </div>
                               
                            
                        </div>}
                        {(this.state.status == "complete" && this.state.mode == "test") && <div>
                                <h5>Your result</h5>
                                <div className="row">
                                    <div className="col s12">
                                        <p>By: {this.state.who_check}</p>
                                        <p>Your score: {this.state.score}</p>
                                        <textarea style={{"width":"100%"}}>{this.state.notes}</textarea>
                                        <p></p>
                                    </div>    
                                </div>    
                            
                        </div>}
                    </div>
                        
            </div>
            
        </div>);
    }
}


export default Speaking;