const my_environment = document.querySelector("meta[name='environment']").getAttribute("content");
let base_url = "";
switch(my_environment){


	case "local":
		base_url = "http://localhost:8000/";
		break;
	case "production":
		base_url = "https://ielts.duy-khanh.net/"
		break;
}

const Config =  {
	"my_environment":my_environment,
	"url_api":base_url
}

export default Config;