
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Reading from "./pages/Reading";
import Writing from "./pages/Writing";
import ReadingResult from "./pages/ReadingResult";
import Assessment from "./pages/Assessment";
import NoPage from "./pages/NoPage";
import Speaking from "./pages/Speaking";
import TestSound from "./pages/TestSound";
import Listening from "./pages/Listening";



export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="writing" element={<Writing />} />
          <Route path="reading" element={<Reading />} />
          <Route path="your-result" element={<ReadingResult />} />
          <Route path="assessment" element={<Assessment />} />
          <Route path="speaking" element={<Speaking/>}/>
          <Route path="listening" element={<Listening/>}/>
          <Route path="test-sound" element={<TestSound/>}/>
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}