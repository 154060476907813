import React from 'react';
import { useParams } from 'react-router';
import 'materialize-css/dist/css/materialize.css';
import axios from "axios";
import Config from "../Config";
import '../css/pages/Writing.css';
import { Modal } from 'react-materialize';
import M from "materialize-css";


function OptimizedTextComponent(text ) {
	
  


	return <div dangerouslySetInnerHTML={{ __html: text }} />;
  
}
class WritingPart extends React.Component{

	constructor(props) {

		super(props);
		
	}
	

	render(){


		return(<div>
			<h5>WRITING TASK {this.props.m_type == 1 ? "1":"2"}</h5>
			<div>{OptimizedTextComponent(this.props.intro_)}</div>
			<br/>
			<div className="main-intro">{OptimizedTextComponent(this.props.main_intro)}</div>
			<br/>
			<div>{OptimizedTextComponent(this.props.end_)}</div>
			{this.props.m_pic != null && <img style={{'width':"100%"}} src={Config.url_api + "documents/" + this.props.exam_id + "/writing/" + this.props.m_pic} />}

		</div>)
	}
}

class Writing extends React.Component{



	constructor(props) {

		super(props);
		this.state = {
			'part':[],
			'exam_id':null,
			'current':0,
			'current_writing':["",""],
			'loading':false,
			'session_id':null,
			'submit':false,
			"who_check":"",
			"data_submit":null,
			"request_wait":[],
			"time_max":-3600,
			"time_out":false,
			"minutesRemain":60

		}
		this.selectPart = this.selectPart.bind(this)
		this.saveWriting = this.saveWriting.bind(this)
		this.submit = this.submit.bind(this)
		this.acceptRequest = this.acceptRequest.bind(this)
	}

	selectPart(e){
		const part = e.target.dataset.value;
		this.setState({current:part});
	}
	
	
	saveWriting(e){
		const part = e.target.dataset.part;
		let current_writing = this.state.current_writing;
		current_writing[part] = e.target.value;
		this.setState({current_writing:current_writing});
		
	}

	getWordsLen(str) { 

		if(str == "") return 0;
		const array = str.trim().split(/\s+/); 
		return array.length; 
	} 
	componentDidMount(){

		var that = this
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const session_id = urlParams.get("session_id");
		const url_api = Config.url_api;
		axios.get(url_api + "api/exam-writing/" + session_id)
		.then(function(response){
				let minutesRemain = 60;
				
				if(response.data.data.length == 2){
					let data_submit = null;
					let part = [];
					part[0] = response.data.data[0];
					part[1] = response.data.data[1];
					let current_writing = ["",""];
					const submit = response.data.submit;
					if(submit){

						current_writing[0] = response.data.data_submit.part_1;
						current_writing[1] = response.data.data_submit.part_2; 
					}{
						const timeRemain = response.data.session.secondRemain;
						if(that.state.time_max <= timeRemain && timeRemain <= 0 ){
						
							minutesRemain = Math.abs((that.state.time_max - timeRemain)/60);
							minutesRemain = minutesRemain -  1;
							console.log(minutesRemain);

						}
					}
					
					that.setState({
						part:part,
						exam_id:part[0].exam_id,
						session_id:session_id,
						submit:response.data.submit,
						current_writing:current_writing,
						data_submit:response.data.data_submit,
						who_check:response.data.check_name,
					
						minutesRemain:minutesRemain

					},function(){



						const timeCount = setInterval(()=>{

							let minutesRemain = that.state.minutesRemain - 1;
						
							that.setState({minutesRemain:minutesRemain});
							
							if(minutesRemain <= 0){
								
								that.setState({time_out:true});
								
							}
						
						},60000);



					});
					

				}

		});
	}	
	submit(){
		var that = this;
		that.setState({loading:true});
		axios.post(Config.url_api + "api/exam-submit/",{

			type:"writing",
			part:that.state.current_writing,
			session_id:that.state.session_id
			
		})
		.then(function(response){

			window.location.replace(Config.url_api);
		});

	}
	acceptRequest(e){
		const id = e.target.dataset.id;
		const name = e.target.dataset.name;
		
		var that = this;
		axios.post(Config.url_api + "api/request-confirm",{id:id,session_id:this.state.session_id})
		.then(function(response){
			
			let data_submit = that.state.data_submit;
			data_submit.status = "accept";
			that.setState({data_submit:data_submit,who_check:name});

		});


	}
	render(){
		var that = this;
		
		const current = this.state.current;
		const submitStatus = (this.state.data_submit != null && typeof(this.state.data_submit.status) != "undefined" ) ? this.state.data_submit.status:"";
		const hasAccept = (this.state.data_submit != null && typeof(this.state.data_submit.status) != "undefined" && (this.state.data_submit.status == "accept" || this.state.data_submit.status == "done") ) ? true:false;
		const hasDone = (this.state.data_submit != null && typeof(this.state.data_submit.status) != "undefined" && this.state.data_submit.status == "done") ? true:false;
		let countWords = 0;
		if(current == 0 || current == 1){
			countWords = this.getWordsLen(this.state.current_writing[current]);
		}
		
		return(<div>
			<div className="row">
				<div className="col s12"><h4>Writing</h4></div>
				<div>
					{typeof(this.state.part[0]) != "undefined" && <button onClick={this.selectPart} data-value="0" className={"btn select-part-button " + (this.state.current == 0 ? "active":"") }>Part 1</button>}
					{typeof(this.state.part[1]) != "undefined" && <button onClick={this.selectPart} data-value="1" className={"btn select-part-button " + (this.state.current == 1 ? "active":"")}>Part 2</button>}
					{!this.state.submit && <button href="#Modal-12" className="btn modal-trigger select-part-button" >Submit</button>}
					{(!this.state.submit && !this.state.time_out) && <button className="btn yellow darken-4"><i style={{'verticalAlign':"bottom"}} className="material-icons dp48">alarm</i> <span>{Math.round(this.state.minutesRemain)}</span></button>}
					{(submitStatus == 'waiting' && this.state.request_wait.length > 0) && <button href="#Modal-14" className="select-part-button btn modal-trigger">Examiner requests</button>}
					{(submitStatus == "accept" || submitStatus == "done") && <button href="#Modal-14" className="select-part-button btn modal-trigger">Examiner info</button> }
					{submitStatus == "done" && <button onClick={this.selectPart} data-value="2" className={"btn select-part-button " + (this.state.current == 2 ? "active":"")}>Assessment Part 1</button>}
					{submitStatus == "done" && <button onClick={this.selectPart} data-value="3" className={"btn select-part-button " + (this.state.current == 3 ? "active":"")}>Assessment Part 2</button>}
				</div>


				<Modal id="Modal-15">
					{this.state.request_wait.map( (a) => <div key={a.id}><b>{a.name}</b> want to accept your writing <button onClick={that.acceptRequest} data-name={a.name} data-id={a.id} className="btn-small light-green darken-1">Accept</button></div>) }

				</Modal>
				
				<Modal id="Modal-12">
						
						<div>Are you sure to submit?</div>
						<br/>
						<div><button disabled={this.state.loading} onClick={this.submit} className="btn orange darken-1">Submit</button></div>
				</Modal>
			</div>
			<div className="row">

				<div style={{'height':"800px"}} className="col l6">
					<div style={{'height':"800px",'overflowY':"scroll"}}>
						{(typeof(this.state.part[current]) != "undefined" && (current == 0 || current == 1) )  && <WritingPart  {...this.state.part[current]} />}
						{current == 2 && <textarea disabled={true}>{this.state.data_submit.part_1}</textarea>}
						{current == 3 && <textarea disabled={true}>{this.state.data_submit.part_2}</textarea>}
					</div>
				</div>
				<div className="col l6">
					<div style={{'height':"800px"}}>
						{current == 2 && <textarea disabled={true}>{this.state.data_submit.check_part1}</textarea>}
						{current == 3 && <textarea disabled={true}>{this.state.data_submit.check_part2}</textarea>}
						{(typeof(this.state.part[current]) != "undefined" && (current == 0 || current == 1) ) && <textarea disabled={this.state.submit} style={{'height':"95%"}} data-part={this.state.current} onChange={this.saveWriting}  value={this.state.current_writing[current]} ></textarea>}
						{(current == 0 || current == 1) && <div>Word count: {countWords}</div>}
					</div>
				</div>
			</div>
		
		</div>)
	}
}


export default Writing;
