
import React,{ useMemo } from 'react';
import { useParams } from 'react-router';
import Config from "../Config";

export function OptimizedTextComponent(text ) {
	
  


    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  
}
export function RenderSelectBox(props){

	
	
	return(<select disabled={props.submit} data-id={props.id} onChange={props.getSelectAnswer} style={{'width':"auto","backgroundColor":"beige"}} className=" browser-default"><option>Select your answer</option>{props.answer.map((o,i) =>(

					<option  key={i} value={i}>{o}</option>
					)
	 )}</select>)




}
export class QuesionShow extends React.Component{

	constructor(props) {

		super(props);
		
		
	}

	render(){
		var that = this


		if(this.props.type == "select"){

			const mAnswers = JSON.parse(this.props.answers);

			const questions = Object.keys(this.props.questions).map(function(key){
			
			
			return 	<div className="select-me" key={key}>
						<div><b>{key}.</b> {that.props.questions[key].text}</div>
						<div><RenderSelectBox  id={key} submit={that.props.submit} getSelectAnswer={that.props.getSelectAnswer} answer={mAnswers}  /></div>
						<br/>
					</div>
			});

			return(<div>
				<div className="intro">{OptimizedTextComponent(this.props.introduction)}</div>
				<br/>
				{this.props.exam_type == "reading" && <div className="all-answers">
					{mAnswers.map((o,r)=>(

						<div key={r}><b>{o}</b></div>

					))}
				</div>}
				<br/>
				<div>{questions}</div>
				<br/>
				<br/>
			</div>)

		}else if(this.props.type == "fill_in"){

			const questions = this.props.questions.map(function(o,i){

				return <div style={{'marginBottom':"10px"}} key={i}><span style={{'display':"inline-block","minWidth":"25px"}}><b>{o}.</b></span><input disabled={that.props.submit} onChange={that.props.getFillAnswer} data-id={o} className=" browser-default" type="text"/></div>
			});
			return(<div key={1}>
				<div className="intro">{OptimizedTextComponent(this.props.introduction)}</div>
				<br/>

				{(this.props.pictures != "" && this.props.pictures != null)  && <img style={{'width':"100%"}} src={Config.url_api + "documents/" + this.props.exam_id + "/questions/" + this.props.pictures}/>}
				<div>{questions}</div>
			
				<br/>
				<br/>
			</div>)

		}else{


			return(<div></div>)
		}
		
	}
}

